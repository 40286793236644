/* eslint-disable */
import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "../App.css";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";

import SupervisorLogin from "../pages/availableRiders/storeSupervisorLogin";

import Login from "../pages/login";
import ForgetPassword from "../pages/login/ForgetPassword";
import NewPassword from "../pages/login/NewPassword";

// context
import { useUserState } from "../context/UserContext";
import { authClass } from "../utils/auth.util";
import "react-toastify/dist/ReactToastify.css";
import { fontImport, staticIps } from "../utils/functions";
import IdleTimerContainer from "./ReUseableComponant/IdleTimerContainer";
import img from "../pages/login/bg-1.jfif";
import logo from "../pages/login/login.png";
import axios from "axios";
import localStoreUtil from "../utils/localstore.util";
import { Maintenance } from "../pages/others/Maintenance";
import apiServices from "../services/requestHandler";
import { BASE_URL } from "../services/HttpProvider";

export default function App(props) {
  var { isAuthenticated } = useUserState();
  let [ip, setIp] = React.useState("");
  let [shouldRender, setShouldRender] = React.useState("");

  React.useEffect(() => {
    const sessionChecker = async () => {
      try {
        var allcookies = document.cookie;

        let browserCookies = allcookies.includes("session = true");

        // let varia = await sessionStorage.getItem("session");
        if (browserCookies === false) {
          const user = await localStoreUtil.get_data("agent");
          if (user) {
            const _id = user._id;
            await apiServices.logoutApi(_id);
            await localStoreUtil.remove_all();
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
        setShouldRender(false);
      }
    };
    sessionChecker();
  }, []);

  React.useEffect(() => {
    const checkIPAddress = async () => {
      try {
        const res = await axios.post("https://dserver.dominospizza.az/api/dominos/orders/get-user-ip");
        const checkTime = await axios.get(
          `${BASE_URL}/users/currentTime`
        );
        setIp(res.data.ipAddress);
        const agent = await localStoreUtil.get_data("agent");
        authClass.setUser = agent;
        if (checkTime.data.data) {
          setShouldRender(true);
        } else {
          setShouldRender(false);
        }
      } catch (error) {
        console.log(error);
        setShouldRender(false);
      }
    };
    
    checkIPAddress();
  }, []);

  React.useEffect(() => {
    fontImport();
  }, []);

  return shouldRender !== false ? (
    `${staticIps[`${ip}`]}`.toString() !== ip.toString() ? (
      ip.toString() ? (
        <div
          style={{
            backgroundColor: "black",
            width: " 100%",
            height: "100vh",
            position: "relative",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={img}
            alt="background"
            style={{
              opacity: "0.2",
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              position: "relative",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: "300px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <h1
                style={{
                  marginTop: 40,
                  color: "white",
                  fontFamily: "BabasNeueBold",
                  fontSize: 100,
                  marginBlockStart: "0em",
                  marginBlockEnd: "0em",
                }}
              >
                ACCESS DENIED
              </h1>
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontWeight: 800,
                  fontSize: 40,
                  marginBlockStart: "0em",
                  marginBlockEnd: "0em",
                }}
              >
                OOPS! You don't have access
                <br />
              </p>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "10px",
            }}
          >
            <span
              className="btn-floating btn-fb mx-1 "
              style={{
                textDecoration: "none",
                color: "#9c9c9c",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              &copy; 2019-{new Date().getFullYear()}{" "}
              <a
                href="http://www.hitech-prime.com/"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: 500,
                }}
                target="blank"
              >
                HiTech Prime Private Limited
              </a> {" "} All rights reserved.
            </span>
          </div>
        </div>
      ) : null
    ) : (
      <HashRouter>
        <IdleTimerContainer></IdleTimerContainer>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/app/orders" />} />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/supervisor-login" component={SupervisorLogin} />
          <PublicRoute path="/forget-password" component={ForgetPassword} />
          <PublicRoute path="/reset-password" component={NewPassword} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    )
  ) : (
    <Maintenance />
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          authClass.getUser?.token ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
